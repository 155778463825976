import classes from './Title.module.css'

function Title() {
    return (
        <div>
            <div>
                <h2 className={classes.titleText}>Online Services</h2>
            </div>
        </div>
    );
}

export default Title;